<template>
  <div class="container">
    <div class="row">
      <div id="booking" class="col-xl-5 col-lg-7 col-md-8 mx-auto">
        <h1>{{ $t("Booking") }}</h1>
        <div class="steps">
          <div class="step-complete four-fifth"></div>
          <div class="step-data">
            <p>4/5</p>
          </div>
          <div class="step-incomplete four-fifth"></div>
        </div>
        <form class="details needs-validation" action="" novalidate>
          <div class="summary">
            <!-- Kokkuvõte -->
          </div>
          <div class="vld-parent" v-if="isLoading">
            <img src="@/assets/images/spinner-loop.gif" height="200" />
          </div>
          <template v-else>
            <h2 id="contact-detail">{{ $t("Contacts") }}</h2>
            <div class="form-group">
              <label for="text" class="form-label">{{ $t("First name and family name") }} *</label>
              <input type="text" class="form-control" :value="fullName" :class="{ error: fullNameInit ? false : true }"
                id="full-name" required @change="setFullNamePrep($event.target.value)" />
            </div>
            <div class="form-group">
              <label for="email" class="form-label">{{ $t("E-mail address") }} *</label>
              <input type="email" class="form-control" :value="email" :class="{ error: emailInit ? false : true }"
                id="email" required @change="setEmailPrep($event.target.value)" />

              <div class="alert alert-warning" role="alert" v-if="hasBookingLastHour">
                {{ $t("You have already made a booking in the last hour!") }}
              </div>
            </div>
            <div class="form-group">
              <label for="phone" class="form-label">{{ $t("Phone") }} *</label>
              <input type="phone" class="form-control" :value="phone" :class="{ error: phoneInit ? false : true }"
                id="phone" required @change="setPhonePrep($event.target.value)" />
            </div>

            <div class="checkbox">
              <input type="checkbox" :checked="haveGiftCard" />
              <label for="text" class="form-label" @click="checkHaveGiftCard()" v-html="$t('I have a gift card')">
              </label>
            </div>

            <template v-if="haveGiftCard">
              <p class="small my-2">{{ $t('Make sure the gift card is valid at the time of your booking. Validity, dates, etc. can be found on the gift card.') }}</p>
              <div class="input-group coupon mb-3">
                <input type="text" class="form-control" :value="giftCardCode"
                  :placeholder="$t('Enter the gift card code')" aria-describedby="button-addon2" @input="
                    setGiftCardCode($event.target.value);
                  checkPressedGiftCard = false;
                  " @blur="giftCardCheckPrep()"/>
                <button v-if="giftCardChecking" class="btn btn-outline-secondary loading" type="button">
                  <img src="@/assets/images/spinner-loop.gif" height="30" />
                </button>
                <button v-else class="btn btn-outline-secondary d-flex align-items-center gap-1" type="button" id="button-addon2"
                  :class="{ disabled: !giftCardCode }" @click="giftCardCheckPrep()">
                  <span class="material-icons">
                    add
                  </span> {{ $t("Add") }}
                </button>
              </div>
              
              <p v-if="giftCardError" class="gift-card-invalid-text">
                {{ getGiftCardErrorMessage() }}
              </p>

              <p v-if="validatedGiftCardCodes.length == 1" class="gift-card-valid-text">
                {{ $t("The gift card code is added", { code: validatedGiftCardCodes.join(', ') }) }}
              </p>

              <p v-if="validatedGiftCardCodes.length > 1" class="gift-card-valid-text">
                {{ $t("The gift card codes are added", { code: validatedGiftCardCodes.join(', ') }) }}
              </p>

            </template>

            <div class="checkbox">
              <input type="checkbox" :checked="haveDiscountCode" />
              <label for="text" class="form-label" @click="checkHaveDiscountCode()" v-html="$t('I have a discount code')">
              </label>
            </div>

            <template v-if="haveDiscountCode">
              <template v-if="!discountCodeValid">
                <div class="input-group coupon mb-3">
                  <input type="text" class="form-control" :value="discountCode"
                    :placeholder="$t('Enter the discount code')" aria-describedby="button-addon2" @input="
                      setDiscountCode($event.target.value);
                    checkPressedDiscountCode = false;
                    " @blur="discountCodeCheckPrep()" />
                  <button v-if="discountCodeChecking" class="btn btn-outline-secondary loading" type="button">
                    <img src="@/assets/images/spinner-loop.gif" height="30" />
                  </button>
                  <button v-else class="btn btn-outline-secondary d-flex align-items-center gap-2" type="button" id="button-addon2"
                    :class="{ disabled: !discountCode }" @click="discountCodeCheckPrep()">
                    <span class="material-icons">
                    add
                  </span> {{ $t("Add") }}
                  </button>
                </div>
                <p v-if="!discountCodeValid &&
                  discountCode &&
                  !discountCodeChecking &&
                  checkPressedDiscountCode
                  " class="gift-card-invalid-text">
                  {{ $t("Please check the discount code.") }}
                </p>
              </template>
              <p v-else class="gift-card-valid-text">
                {{ $t("The discount code is valid", { code: discountCode }) }}
              </p>
            </template>

            <hr />

            <p v-if="isPackage">
              {{
                $t("An advance payment of €50 is required to book a package.")
              }}
            </p>
            <div class="checkbox">
              <input type="checkbox" :checked="termsAndCondition" />
              <label for="text" class="form-label" @click="checkTermsAndCondition()" v-html="$t(
                'I agree with the general terms and conditions of purchase'
              )
                ">
              </label>
            </div>
            <div class="row">
              <div class="col-6 text-center">
                <router-link to="/step3">
                  <a href="#" class="btn btn-secondary">{{ $t("Go back") }}</a>
                </router-link>
              </div>
              <div class="col-6 text-center" @click="inputCheck()">
                <a href="#contact-detail" type="submit" class="btn btn-primary" :class="{
                  disabled: !termsAndCondition || sendingBookingRequest,
                }">{{ $t("Book") }}</a>
              </div>
            </div>
          </template>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  mounted() {
    this.setIsLoading(false);
    if (!this.checkRequiredInput()) {
      this.$router.push("step3");
    }

    if (this.discountCode) {
      this.haveDiscountCode = true;
      this.discountCodeCheckPrep();
    }

    this.clearGiftCards();
  },

  data() {
    return {
      fullNameInit: true,
      emailInit: true,
      phoneInit: true,
      termsAndCondition: false,
      haveGiftCard: false,
      haveDiscountCode: false,
      checkPressedGiftCard: false,
      checkPressedDiscountCode: false,
      termsAndConditionsUrl: process.env.VUE_APP_TERMS_AND_CONDITIONS_URL,
      sendingBookingRequest: false,
    };
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.is_loading,
      errors: (state) => state.errors,
      isBooked: (state) => state.is_booked,
      fullName: (state) => state.full_name,
      email: (state) => state.email,
      phone: (state) => state.phone,
      isPackage: (state) => state.is_package,
      bookTime: (state) => state.book_time,
      giftCardCode: (state) => state.gift_card_code,
      giftCardError: (state) => state.gift_card_error,
      validatedGiftCardCodes: (state) => state.validated_gift_cards,
      giftCardChecking: (state) => state.gift_card_checking,
      discountCode: (state) => state.discount_code,
      discountCodeValid: (state) => state.discount_code_valid,
      discountCodeChecking: (state) => state.discount_code_checking,
      hasBookingLastHour: (state) => state.has_booking_last_hour,
    }),
  },
  watch: {
    isBooked(newVal) {
      if (newVal) {
        this.$router.push("/booking-confirmed");
      }
    },
  },
  methods: {
    ...mapMutations([
      "setFullName",
      "setEmail",
      "setPhone",
      "setGiftCardCode",
      "setDiscountCode",
      "setIsLoading",
      "clearGiftCards",
    ]),
    ...mapActions(["storeBooking", "giftCardCheck", "discountCodeCheck", "checkIfHasBookingLastHour",]),
    setFullNamePrep(value) {
      if (this.fullName) {
        this.fullNameInit = false;
      } else {
        this.fullNameInit = true;
      }
      this.setFullName(value);
    },
    setEmailPrep(value) {
      if (this.validateEmail(value)) {
        this.emailInit = true;
      } else {
        this.emailInit = false;
      }
      this.setEmail(value);
      this.sendDataToUpScale();

      // Check if the customer has made a booking in last 60 minutes (just informative, to avoid double booking)
      this.checkIfHasBookingLastHour();
    },
    sendDataToUpScale() {
      if (this.validateEmail(this.email)) {
        // eslint-disable-next-line no-undef
        mt("send", "pageview", { email: this.email, started_booking: true });
      }
    },
    setPhonePrep(value) {
      if (this.validatePhone(value)) {
        this.phoneInit = true;
      } else {
        this.phoneInit = false;
      }
      this.setPhone(value);
    },
    storeBookingProcess() {
      this.storeBooking();
    },
    inputCheck() {
      if (
        this.fullName &&
        this.validateEmail(this.email) &&
        this.validatePhone(this.phone) &&
        this.termsAndCondition
      ) {
        // Set button disabled
        this.sendingBookingRequest = true;

        this.storeBookingProcess();
        this.setIsLoading(true);
      }
    },
    checkRequiredInput() {
      return this.bookTime ? true : false;
    },
    validateEmail(value) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    },
    validatePhone(value) {
      if (value !== "") {
        return String(value).toLowerCase();
      }

      return false;
    },
    giftCardCheckPrep() {
      this.giftCardCheck();
      this.checkPressedGiftCard = true;
    },
    discountCodeCheckPrep() {
      this.discountCodeCheck();
      this.checkPressedDiscountCode = true;
    },
    checkTermsAndCondition() {
      this.termsAndCondition = !this.termsAndCondition;
    },
    checkHaveGiftCard() {
      this.haveGiftCard = !this.haveGiftCard;
    },
    getGiftCardErrorMessage() {
      const errorMessages = {
        "GIFTCARD_USED": this.$t('GIFTCARD_USED'),
        "GIFTCARD_EXPIRED": this.$t('GIFTCARD_EXPIRED'),
        "GIFTCARD_HAS_BOOKING": this.$t('GIFTCARD_HAS_BOOKING'),
        "GIFTCARD_NOT_VALID_ON_REQUESTED_DATE": this.$t('GIFTCARD_NOT_VALID_ON_REQUESTED_DATE'),
        "GIFTCARD_NOT_VALID_AT_REQUESTED_TIME": this.$t('GIFTCARD_NOT_VALID_AT_REQUESTED_TIME'),
        "GIFTCARD_NOT_VALID_WITH_REQUESTED_SERVICE": this.$t('GIFTCARD_NOT_VALID_WITH_REQUESTED_SERVICE'),
        "GIFTCARD_NOT_VALID_AT_REQUESTED_LOCATION": this.$t('GIFTCARD_NOT_VALID_AT_REQUESTED_LOCATION'),
        "GIFTCARD_NOT_FOUND": this.$t('GIFTCARD_NOT_FOUND'),
      }

      return errorMessages[this.giftCardError] || this.giftCardError;
    },
    checkHaveDiscountCode() {
      this.haveDiscountCode = !this.haveDiscountCode;
    },
  },
};
</script>
<style scoped>
.vld-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.btn-outline-secondary.loading {
  background-color: #fff !important;
  border-color: #4bb449 !important;
  min-width: 120px;
}

.btn-outline-secondary.loading:hover {
  background-color: #fff !important;
  border-color: #4bb449 !important;
  min-width: 120px;
}

.gift-card-status {
  position: relative;
  margin-left: -50px !important;
  padding-right: 8px !important;
  margin-top: 8px;
  z-index: 9999 !important;
}
.checkbox {
  margin-bottom: 16px;
}

.checkbox input,
.checkbox label {
  cursor: pointer !important;
}

.gift-card-valid-text {
  color: #4bb449 !important;
}

.gift-card-invalid-text {
  color: red !important;
}
</style>
